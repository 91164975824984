$screen-xl: 2160px;
$screen-lg: 1440px;
$screen-md: 1023px;
$screen-sm: 767px;
$screen-xs: 390px;

$custom-easing: cubic-bezier(0.645, 0.045, 0.355, 1);

$color-green-CEA: #61a963;
$color-green-CEA-light: rgba(97, 169, 99, 0.12);
$color-green-CEA-dark: #447444;
$color-gray-medium: rgba(0, 0, 0, 0.54);
$color-gray-light: #fafafa;
$color-gray-light-2: #e3e4e6;
$color-gray-light-3: rgba(0, 0, 0, 0.06);
$color-gray-dark: rgba(0, 0, 0, 0.87);
$color-gray-dark-2: rgba(0, 0, 0, 0.6);
$color-grayscale: #fafafa;

$color-generic-white: #ffffff;
$color-generic-black: #000000;

$color-transparent-1: rgba(0, 0, 0, 0.87);
$color-transparent-2: rgba(0, 0, 0, 0.54);
$color-transparent-3: rgba(248, 250, 252, 0.5);

$color-success-main: #10b981;
$color-destructive-main: #d32f2f;
$color-destructive-dark: #a82626;
$color-disabled: #d9d8d7;

$box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2),
  0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);

$border-radius: 4px;

$spacing-xs: 4px;
$spacing-sm: 8px;
$spacing-md: 16px;
$spacing-lg: 24px;
$spacing-xl: 32px;
$spacing-xxl: 48px;

$font-size-medium: 16px;
$font-size-small: 14px;

$header-height: 64px;
$footer-height: 64px;
$main-height: calc(100vh - #{$header-height} - #{$footer-height});

$side-menu-width: 256px;

$error-timer: 3000;
$success-timer: 1500;
$info-timer: 3000;
$warning-timer: 3000;

$min-width-chip: 112px;

:export {
  screen-xl: $screen-xl;
  screen-lg: $screen-lg;
  screen-md: $screen-md;
  screen-sm: $screen-sm;
  screen-xs: $screen-xs;

  custom-easing: $custom-easing;

  color-green-CEA: $color-green-CEA;
  color-green-CEA-light: $color-green-CEA-light;
  color-green-CEA-dark: $color-green-CEA-dark;
  color-gray-medium: $color-gray-medium;
  color-gray-light: $color-gray-light;
  color-gray-light-2: $color-gray-light-2;
  color-gray-light-3: $color-gray-light-3;
  color-gray-dark: $color-gray-dark;
  color-gray-dark-2: $color-gray-dark-2;
  color-grayscale: $color-grayscale;

  color-generic-white: $color-generic-white;
  color-generic-black: $color-generic-black;

  color-transparent-1: $color-transparent-1;
  color-transparent-2: $color-transparent-2;
  color-transparent-3: $color-transparent-3;

  color-success-main: $color-success-main;
  color-destructive-main: $color-destructive-main;
  color-destructive-dark: $color-destructive-dark;
  color-disabled: $color-disabled;

  box-shadow: $box-shadow;

  border-radius: $border-radius;

  spacing-xs: $spacing-xs;
  spacing-sm: $spacing-sm;
  spacing-md: $spacing-md;
  spacing-lg: $spacing-lg;
  spacing-xl: $spacing-xl;
  spacing-xxl: $spacing-xxl;

  font-size-medium: $font-size-medium;
  font-size-small: $font-size-small;

  header-height: $header-height;
  footer-height: $footer-height;
  main-height: $main-height;

  side-menu-width: $side-menu-width;

  error-timer: $error-timer;
  success-timer: $success-timer;
  info-timer: $info-timer;
  warning-timer: $warning-timer;

  min-width-chip: $min-width-chip;
}
