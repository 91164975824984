.leaflet-tooltip {
  padding: 1px 4px 0px 4px;
}

.unselectable {
  cursor: grab;
}

.selectable {
  cursor: pointer;
}
